import { RestrictedPage } from '@/components/RestrictedPage';
import { supportEmail } from '@/constants';
import { roles } from '@/utils/hasRole';
import { trpc } from '@/utils/trpc';
import { EnvelopeIcon } from '@heroicons/react/24/solid';

export default function IndexPage() {
  const userInfo = trpc.user.getUserInfo.useQuery();
  const isPublicUser = !userInfo.data?.schoolId;
  return (
    <RestrictedPage
      permittedRoles={[roles.TEACHER, roles.ADMIN]}
      redirectUrl="/tools/tutor"
    >
      <div className="mx-auto max-w-7xl space-y-4">
        <h2 className="text-3xl font-extrabold text-gray-900">
          Welcome to MySchoolAi
        </h2>
        <p>Thank you for agreeing to be part of this early access preview!</p>
        <p>
          Our mission is to empower educators by providing them with
          cutting-edge tools that save time, increase efficiency, and enhance
          the educational experience for students. We will be adding more tools
          in the coming weeks and months that continue to build on this
          philosophy.
        </p>
        {isPublicUser && (
          <>
            <h3 className="text-2xl font-bold text-gray-900">How it works</h3>
            <p>
              All users who have been invited as part of our early access
              preview are given 100 free credits each month. Each time you use a
              tool, a credit is consumed. Once your monthly credits expire, you
              will need to wait until the 1st of the following month to be able
              to use the tools again.
            </p>
          </>
        )}
        <h3 className="text-2xl font-bold text-gray-900">
          Share your feedback
        </h3>
        <p>
          We welcome any feedback about the existing suite of tools and would
          love to hear your thoughts on how we can further simplify your
          workload.
        </p>
        <a
          href={`mailto:${supportEmail}`}
          className="dark:focus:ring-blue-600/55 mb-2 mr-3 inline-flex items-center rounded-lg bg-blue-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-600/90 focus:outline-none focus:ring-4 focus:ring-blue-600/50"
        >
          <EnvelopeIcon className="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
          Send us an email!
        </a>
        <a
          href="https://twitter.com/MySchoolAI"
          target="_blank"
          rel="noopener noreferrer"
          className="dark:focus:ring-[#1da1f2]/55 mb-2 mr-3 inline-flex items-center rounded-lg bg-[#1da1f2] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#1da1f2]/90 focus:outline-none focus:ring-4 focus:ring-[#1da1f2]/50"
        >
          <svg
            className="-ml-1 mr-2 h-4 w-4"
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="twitter"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"
            ></path>
          </svg>
          Follow us on Twitter
        </a>
        <a
          href="https://www.linkedin.com/company/myschoolai/"
          target="_blank"
          rel="noopener noreferrer"
          className="dark:focus:ring-[#0077b5]/55 mb-2 mr-3 inline-flex items-center rounded-lg bg-[#0077b5] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#0077b5]/90 focus:outline-none focus:ring-4 focus:ring-[#0077b5]/50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-ml-1 mr-2 h-4 w-4"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
          </svg>
          Follow us on LinkedIn
        </a>
      </div>
    </RestrictedPage>
  );
}
