import { Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from './AuthProvider';
import { UserRole } from '@prisma/client';
import { hasRole } from '@/utils/hasRole';
import LoadingSpinner from './LoadingSpinner';

export const RestrictedPage = ({
  children,
  permittedRoles = [],
  redirectUrl = '/',
}: {
  children: React.ReactNode;
  permittedRoles: UserRole[];
  redirectUrl?: string;
}) => {
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    if (typeof window !== 'undefined' && !hasRole(user, permittedRoles)) {
      router.push(redirectUrl);
    }
  }, []);

  if (!user || !hasRole(user, permittedRoles)) return <LoadingSpinner />;

  return <Fragment>{children}</Fragment>;
};
